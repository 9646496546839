import { client } from '../axios'

export const validateLogin = async function (data) {
  try {
    const result = await client.post('/api/validate_login', {
      email: data.email.toLowerCase(),
      pwd: data.pwd,
    })
    return result
  } catch (e) {
    console.log('An error occurred while saving the information.', e)
    return e
  }
}

export const getSignature = async (address, category) => {
  try {
    const { data } = await client.post('/api/sign', {
      address,
      category,
    })
    return data.signature
  } catch (e) {
    console.error('An error occurred while getting signature.', e)
    throw e
  }
}

export const addNewForm = async (data) => {
  try {
    const newForm = await client.put('/api/add_new_form', {
      form: {
        status: 'pending',
        form: data.form,
      },
      files: data.files,
    })
    return { formId: newForm.data }
  } catch (e) {
    console.log('An error occurred while saving the information.', e)
    return e
  }
}

export const getAllForms = async function (status) {
  try {
    const result = await client.get('/api/get_forms?status=' + status)
    return result
  } catch (e) {
    console.log('An error occurred while saving the information.', e)
    return e
  }
}

export const exportSelected = async function (ids) {
  try {
    window.open(
      process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL +
        '/api/export?ids=' +
        ids.join(','),
      '_blank'
    )
  } catch (e) {
    console.log('An error occurred while exporting.', e)
    return e
  }
}

export const getForm = async function (id) {
  try {
    const result = await client.post('/api/get_form', { id: id })
    return result
  } catch (e) {
    console.log('An error occurred while saving the information.', e)
    return e
  }
}

export const getCustomer = async function (name) {
  try {
    const result = await client.post('/api/get_customer', { name: name })
    return result
  } catch (e) {
    console.log('An error occurred while saving the information.', e)
    return e
  }
}

export const approveForm = async function (data) {
  try {
    const result = await client.post('/api/approve_form', {
      id: data.id,
      form: data.form,
    })
    return result
  } catch (e) {
    console.log('An error occurred while saving the information.', e)
    return e
  }
}

export const rejectForm = async function (data) {
  try {
    const result = await client.post('/api/reject_form', {
      id: data.id,
      form: data.form,
    })
    return result
  } catch (e) {
    console.log('An error occurred while saving the information.', e)
    return e
  }
}

export const setGooglePlaces = function (input) {
  var options = {
    types: ['address'],
    componentRestrictions: {
      country: 'us',
    },
  }
  const autocomplete = new window.google.maps.places.Autocomplete(
    input,
    options
  )

  return autocomplete
}
